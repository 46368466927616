import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

interface Props {
  description?: string;
  lang?: string;
  meta?: [];
  keywords?: string[];
  title: string;
  image?: string;
}
const SEO = (data: Props) => {
  const { description, lang, meta, keywords, title, image, script } = data;

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        let shareImage = image || data.contentfulWebsiteConfiguration?.shareImage?.fluid?.src;
        if (shareImage && shareImage.indexOf("//") === 0) {
          shareImage = shareImage.replace("//", "https://");
        }

        const metaDescription = description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | Fackförbundet ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title + " | Fackförbundet " + data.site.siteMetadata.title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: shareImage,
              },
              {
                name: "facebook-domain-verification",
                content: "ivu235tenxl7ptcu8vrn0hwpiaaj19",
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta ? meta : [])}
          >
            <link rel="manifest" href="/favicon/manifest.json"></link>
            <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png"></link>
            <link rel="icon" href="/favicon/favicon.ico"></link>
            <link rel="stylesheet" href="/cookiebot.css"></link>
          </Helmet>
        );
      }}
    />
  );
};

SEO.defaultProps = {
  lang: `sv-se`,
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    contentfulWebsiteConfiguration {
      shareImage {
        title
        fluid {
          src
        }
      }
    }
  }
`;
